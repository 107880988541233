<template>
  <div class="main">
    <!-- 修改密码 -->
    <div class="pop-wrapper" style="padding-top: 6%">
      <div class="login-bg"></div>
      <div class="login-wrapper" ref="loginWrapperDom">
        <div class="login">
          <div class="login-title">
            <span class="lj-icon-compass">灯塔智能生涯规划与数据分析系统</span>
          </div>
          <div class="lj-form login-form">
            <fieldset>
              <!-- <div class="lj-form-group">
                <input
                  class="lj-radius"
                  type="text"
                  name="number"
                  placeholder="请输入您的账号"
                  required=""
                  v-model="ruleForm.username"
                  oninput="(function(input){ input.value = input.value.trim() })(this)"
                />
                <div class="input-icon-wrapper">
                  <span class="lj-icon-user"></span>
                </div>
              </div> -->
              <div class="lj-form-group">
                <input
                  class="lj-radius"
                  type="password"
                  name="password"
                  placeholder="请输入您的密码"
                  required=""
                  v-model="ruleForm.password"
                />
                <div class="input-icon-wrapper">
                  <span class="lj-icon-key"></span>
                </div>
              </div>
              <div class="lj-form-group">
                <input
                  class="lj-radius"
                  type="password"
                  name="password"
                  placeholder="请输入您的新密码"
                  required=""
                  v-model="ruleForm.newpassword"
                />
                <div class="input-icon-wrapper">
                  <span class="lj-icon-key"></span>
                </div>
              </div>
              <div class="lj-form-group">
                <input
                  class="lj-radius"
                  type="password"
                  name="password"
                  placeholder="请确认您的新密码"
                  required=""
                  v-model="ruleForm.newpassword2"
                />
                <div class="input-icon-wrapper">
                  <span class="lj-icon-key"></span>
                </div>
              </div>
              <p>
                <button
                  class="lj-btn lj-btn-primary lj-btn-block"
                  @click.prevent="resetPwd"
                  style="margin-bottom: 1rem"
                >
                  重置密码
                </button>
                <button
                  class="lj-btn lj-btn-third lj-btn-block"
                  @click.prevent="backPage"
                  style="margin-bottom: 1rem; background: #209ff0"
                >
                  返回
                </button>
              </p>
            </fieldset>
          </div>
          <hr />
          <p style="margin: 0">欢迎使用灯塔智能生涯规划与数据分析系统</p>
          <p style="margin-bottom: 0">希望我们的智能分析结果能给您带来帮助！</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FirstLoginResetPwd } from './../../../api/userinfo'
import md5 from 'js-md5'

export default {
  data: function () {
    return {
      ruleForm: {
        //username和password默认为空
        username: '',
        password: '',
        newpassword: '',
        newpassword2: ''
      }
    }
  },
  computed: {
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    },
    isLoggedIn: function () {
      return this.$store.state.User.haslogin
    }
  },
  methods: {
    resetPwd: function () {
      if (this.ruleForm.password == '') {
        this.$message.error('请输入原密码')
      } else if (this.ruleForm.newpassword == '') {
        this.$message.error('请输入新密码')
      } else if (this.ruleForm.newpassword != this.ruleForm.newpassword2) {
        this.$message.error('两次输入的密码不一致')
      } else {
        FirstLoginResetPwd({
          oldpassword: md5(this.ruleForm.password),
          newpassword: md5(this.ruleForm.newpassword)
        }).then(res => {
          if (res.rescode == 200) {
            this.$message('修改密码成功,3s后自动返回')
            setTimeout(() => {
              this.$router.back()
            }, 3000)
          } else{
            this.$message('修改密码错误')
          }
        })
      }
    },
    backPage: function () {
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./../../../Public/resources/css/templates/home/index/index.min.css');
</style>
